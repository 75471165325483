import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async downloadExcel(filter) {
        const response = await httpService.downloadExcelWithFilter('Reports/MonitoringProgressPaymentsExcel', filter);
        return response;
    },
    async getList(filter) 
    {
        const response = await httpService.get(`Reports/MonitoringProgressPayments`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: this.default()
        };
        
        if (!response.status) 
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        
        const dataValue = response.data.data[0];

        result.data.submitted = dataValue.submitted;
        result.data.approved = dataValue.approved;
        result.data.rejected = dataValue.rejected;
        result.data.poCreated = dataValue.poCreated;
        result.data.waitingForPayment = dataValue.waitingForPayment;
        result.data.paid = dataValue.paid;
        
        for (let i = 0; i < dataValue.data.length; i++) {
            result.data.data.push(this._toObject(dataValue.data[i]));    
        }

        response.data = result;
        return response;
    },
    
    default() {
        return {
            submitted: 0,
            approved: 0,
            rejected: 0,
            poCreated: 0,
            waitingForPayment: 0,
            paid: 0,
            data: new Array()
        };
        // return {
        //     id: null,
        //     vendorId: null,
        //     vendorCode: null,
        //     vendorName: null,
        //     deliveryTicket: null,
        //     vendorInvoiceNo: null,
        //     invoiceTypeId: null,
        //     invoiceTypeDescription: null,
        //     invoiceDate: null,
        //     submitDate: null,
        //     deliveryDate: null,
        //     qtyInGal: null,
        //     totalPrice: null,
        //     currency: null,
        //     soNumber: null,
        //     amountSOSAPInUSD: null,
        //     soCorrection: null,
        //     amountSAPCorrection: null,
        //     prNumber: null,
        //     poNumber: null,
        //     miroDoc: null,
        //     paymentDueDate: null,
        //     receiptStatusId: null,
        //     receiptStatusDescription: null,
        //     paymentStatus: null
        // };
    },

    _toObject(jsonData) {
        const result = this.default();

        result.id = jsonData.id;
        result.vendorId = jsonData.vendorId;
        result.vendorCode = jsonData.vendorCode;
        result.vendorName = jsonData.vendorName;
        result.deliveryTicket = jsonData.deliveryTicket;
        result.vendorInvoiceNo = jsonData.vendorInvoiceNo;
        result.invoiceTypeId = jsonData.invoiceTypeId;
        result.invoiceTypeDescription = jsonData.invoiceTypeDescription;
        result.invoiceDate = jsonData.invoiceDate;
        result.submitDate = jsonData.submitDate;
        result.deliveryDate = jsonData.deliveryDate;
        result.qtyInGal = jsonData.qtyInGal;
        result.totalPrice = jsonData.totalPrice;
        result.currency = jsonData.currency;
        result.soNumber = jsonData.soNumber;
        result.amountSOSAPInUSD = jsonData.amountSOSAPInUSD;
        result.soCorrection = jsonData.soCorrection;
        result.amountSAPCorrection = jsonData.amountSAPCorrection;
        result.prNumber = jsonData.prNumber;
        result.poNumber = jsonData.poNumber;
        result.miroDoc = jsonData.miroDoc;
        result.paymentDueDate = jsonData.paymentDueDate;
        result.receiptStatusId = jsonData.receiptStatusId;
        result.receiptStatusDescription = jsonData.receiptStatusDescription;
        result.paymentStatus = jsonData.paymentStatus;
        result.qty = jsonData.qty;
        result.unitCode = jsonData.unitCode;
        result.unitDescription = jsonData.unitDescription;
        result.invoiceStatusDescription = jsonData.invoiceStatusDescription;
        return result;
    }
});