<template>
    <router-view></router-view>

    <bs-search-form>
        <template v-slot:title>Monitoring Invoice Conco Delco</template>
        <template v-slot:content>
            <div class="col-lg-6 col-sm-12">
                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Submit Date</label>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.submitDateFrom" />
                    </div>
                    <div class="col-sm-4">
                        <bs-date-picker v-model="filter.submitDateUntil" />
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="filterCustomer" class="col-sm-4 col-form-label">Vendor</label>
                    <div class="col-sm-8">
                        <vendor-combo v-model="filter.vendorIdEquals" :textDefault="filter.vendorIdEquals" />
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-sm" @click="loadData()"><i class="bi bi-search"></i> Search</button>
            <button class="btn btn-primary btn-sm ms-1" @click="downloadExcel">
                <i class="bi bi-file-earmark-excel"></i> Download Excel
            </button>
        </template>
    </bs-search-form>

    <div class="row justify-content-between">
        <div class="col-md-6">
            
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>Vendor Code</th>
                    <th>Vendor Name</th>
                    <th>Receipt Number</th>
                    <th>Vendor Invoice</th>
                    <th>Invoice Date</th>
                    <th>Submit Date</th>
                    <th>Delivery Date</th>
                    <th>Qty</th>
                    <th>UoM</th>
                    <th>Total Price</th>
                    <th>Curr</th>
                    <th>SO</th>
                    <th>Amount SO SAP in USD</th>
                    <th>SO Correction</th>
                    <th>Amount SO Correction</th>
                    <th>PR</th>
                    <th>PO</th>
                    <th>MIRO</th>
                    <th>Payment Due Date</th>
                    <th>Invoice Status</th>
                    <th>Receipt Status</th>
                    <th>Payment Status</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data.data != null && data.data.length > 0">
                    <tr v-for="item in data.data" :key="item.id">
                        <td>{{item.vendorCode}}</td>
                        <td>{{item.vendorName}}</td>
                        <td>{{item.deliveryTicket}}</td>
                        <td>{{item.vendorInvoiceNo}}</td>
                        <td><view-date v-model="item.invoiceDate" /></td>
                        <td><view-date v-model="item.submitDate" /></td>
                        <td><view-date v-model="item.deliveryDate" /></td>
                        
                        <!-- TODO: GANTI INI JADI QTY USER dan UOMny -->
                        <td><bs-plain-number v-model="item.qty" /></td>
                        <td>{{ item.unitCode }}</td>
                        <!-- TODO: GANTI INI JADI QTY USER dan UOMny -->

                        <td><bs-plain-number v-model="item.totalPrice" /></td>
                        <td>{{item.currency}}</td>
                        <td>{{item.soNumber}}</td>
                        <td><bs-plain-number v-model="item.amountSOSAPInUSD" /></td>
                        <td>{{item.soCorrection}}</td>
                        <td><bs-plain-number v-model="item.amountSAPCorrection" /></td>
                        <td>{{item.prNumber}}</td>
                        <td>{{item.poNumber}}</td>
                        <td>{{item.miroDoc}}</td>
                        <td><view-date v-model="item.paymentDueDate" /></td>
                        <td>{{item.invoiceStatusDescription}}</td>
                        <td>{{item.receiptStatusDescription}}</td>
                        <td>{{item.paymentStatus}}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div class="row justify-content-between">
        <div class="col-md-4 col-sm-6 col-xs-12" style="border: 1px solid #b3b3b3;margin-top:5px;margin-bottom:5px">
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <b>Summary Status</b>
                </div>
            </div>

            <hr />

            <div class="row mb-1">
                <div class="col-md-6">Submitted</div>
                <div class="col-md-6 float-right"><bs-plain-number v-model="data.submitted" :decimalPlace="0" /></div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6">Approved</div>
                <div class="col-md-6"><bs-plain-number v-model="data.approved" :decimalPlace="0" /></div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6">Rejected</div>
                <div class="col-md-6"><bs-plain-number v-model="data.rejected" :decimalPlace="0" /></div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6">PO Created</div>
                <div class="col-md-6"><bs-plain-number v-model="data.poCreated" :decimalPlace="0" /></div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6">Waiting For Payment</div>
                <div class="col-md-6"><bs-plain-number v-model="data.waitingForPayment" :decimalPlace="0" /></div>
            </div>

            <div class="row mb-1">
                <div class="col-md-6">Paid</div>
                <div class="col-md-6"><bs-plain-number v-model="data.paid" :decimalPlace="0" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressPaymentService from "@/services/progress-payment-service.js";
import ViewDate from '../../../components/ViewDate.vue';
import BsPlainNumber from '../../../plugins/components/BsPlainNumber.vue';

export default {
  components: { ViewDate, BsPlainNumber },
    data: function () {
        let currentDate = new Date();
        return {
            filter: {
                submitDateFrom: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
                submitDateUntil: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
                vendorIdEquals: null,
                page: 1,
                perPage: 10,
                orderByProperty: 'submitDate',
                orderType: 1
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: ProgressPaymentService.default(),
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/progress-payment") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async loadData() {
            const result = await ProgressPaymentService.getList(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = data.data;
        },
        async downloadExcel() {
          try {
            this.$store.commit('progressCounterQueueIncrement');
            await ProgressPaymentService.downloadExcel(this.filter);
          }
          finally {
            this.$store.commit('progressCounterQueueDecrement');
          }
        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>